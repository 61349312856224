
// variables used to style selected plans
$selected-color: var(--primary)
$selected-border-size: 3px

.upgrade-features
  // this makes the list items look nice on mobile
  li
    display: flex
    .pg-icon
      margin-right: .5rem
    .upgrade-feature
      width: 100%

#subscription-details-table
  .subscription-detail
    display: flex
    flex-direction: row
    align-items: flex-start
    margin: .5rem
    .subscription-detail-label
      width: 16rem
      text-align: right
      margin-right: 1rem
    .subscription-detail-value
      width: 100%
    .subdetail
      font-size: .8rem
      margin-top: .2rem

#plan-selector
  .plan
    height: 100%  // fill the whole column
    // prevent shifting when border is added
    border: $selected-border-size solid transparent
    border-radius: 8px
    cursor: pointer
    padding: 1rem

    &.is-selected
        border: $selected-border-size solid $selected-color

    .plan-summary
      display: flex
      flex-direction: row
      align-items: center
    .price
      font-size: 1.5rem
