

#profile-picture
  display: flex
  justify-content: center
  img.avatar
    object-fit: cover
    width: 128px
    height: 128px
    border-radius: 50%


#profile-upload-holder
  padding-top: .5em

  input[type="file"]
    display: none
